import NavBar from "../../components/navbar";
import Theme from "../../assets/theme/theme";
import Home from "./home";
import About from "./about";
import Skills from "./skills";
import Portfolio from "./portfolio";
import Services from "./services";
import Contact from "./contact";
import Footer from "./footer";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { CssBaseline } from "@mui/material";

import { useState, createContext } from "react";
import { useLocation } from "react-router-dom";

export const LanguageContext = createContext();

export default function Index(props) {
  const location = useLocation();

  const language = location.state === null ? "en" : location.state;
  const [lang, setLang] = useState(language === "en" ? "en" : "hu");
  const toggleLang = () => {
    setLang((prevLang) => (prevLang === "hu" ? "en" : "hu"));
  };

  return (
    <div>
      <LanguageContext.Provider value={{ lang, toggleLang }}>
        <Theme>
          <CssBaseline />
          <NavBar />

          <Box
            sx={{
              display: "flex",
              overflowX: "hidden",
            }}
          >
            <Box
              component="main"
              sx={{
                p: 1,
                width: "100%",
                maxWidth: "md",
                padding: "1rem",
                mx: "auto",
              }}
            >
              <Toolbar />

              <Home />
              <About />
              {/*<Skills />
              <Portfolio />
          <Services />*/}
              <Contact />
              <Footer />
            </Box>
          </Box>
        </Theme>
      </LanguageContext.Provider>
    </div>
  );
}
