import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import LanguageIcon from "@mui/icons-material/Language";
import { LanguageContext } from "../pages/home";

import CachedNavItems from "./navitems";

const drawerWidth = 200;

const navItems = [
  { en: "Home", hu: "Kezdőlap" },
  { en: "About", hu: "Rólam" },
  /*{en:'Skills',
   hu:'Erősségeim'}, 
   {en:'Portfolio',
   hu:'Portfólió'}, 
   {en:'Demos',
   hu:'Demók'},*/
  { en: "Contact", hu: "Kapcsolat" },
];

function scrollTo(item) {
  var element = document.getElementById(item);
  if (item !== "Home") {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function NavBar() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const { lang, toggleLang } = React.useContext(LanguageContext);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {lang === "hu" ? (
          <>
            <span className="font-medium">Kiss</span> Benedek{" "}
          </>
        ) : (
          <>
            Benedek <span className="font-medium">Kiss</span>
          </>
        )}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton onClick={() => scrollTo(item.en)} sx={{ textAlign: "center" }}>
              {lang === "hu" ? <ListItemText primary={item.hu} /> : <ListItemText primary={item.en} />}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav">
        <Container maxWidth="md">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="div" sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                {lang === "hu" ? (
                  <>
                    <span className="font-medium">Kiss</span> Benedek{" "}
                  </>
                ) : (
                  <>
                    Benedek <span className="font-medium">Kiss</span>
                  </>
                )}
              </Typography>
              <IconButton sx={{ ml: "1rem" }} onClick={colorMode.toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              <Button sx={{ ml: "1rem" }} onClick={() => toggleLang()} color="inherit">
                <LanguageIcon style={{ minWidth: "30px" }} />
                {lang === "hu" ? "HU" : "EN"}
              </Button>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <CachedNavItems navitems={navItems} lang={lang} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
